import React from 'react'
import { Text } from './style'
import PropTypes from 'prop-types'
import { useServices } from '../../../../hooks/useServices'

export const New = ({ icon, name, service, from }) => {
  const { handleClickService } = useServices()
  return (
    <div
      onClick={() => handleClickService(service, from)}
      style={{
        width: '33%',
        borderRadius: 8,
        boxShadow: '0px 2px 4px rgba(34, 40, 49, 0.1)',
        padding: '10px',
        maxWidth: '100px',
        cursor: 'pointer'
      }}
    >
      <img
        style={{
          width: '100%',
          height: 'auto',
          objectFit: 'cover',
          borderRadius: 8
        }}
        src={icon}
      />
      <Text>{name}</Text>
    </div>
  )
}

New.propTypes = {
  icon: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  service: PropTypes.object.isRequired
}
