import styled from 'styled-components/macro'

export const Title = styled.h2` 
    margin: ${props => props?.mode ? '0 0 12px 0' : '0 0 12px 0'};
    font-size: 16px;
    color: #222831;
`

export const NewsContainer = styled.div`  
    display: flex;
    align-items: center;
    justify-content: space-between;  
    overflow: auto;
    overflow-y: hidden;  
    padding-bottom: 10px;
    gap: 10px;
`

export const Container = styled.div`  
`
