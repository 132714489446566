import styled from 'styled-components/macro'
import { Button } from '@material-ui/core'

export const ServiceContainer = styled(Button)`
    &:last-child {
        margin-bottom: ${props => props?.mode ? '0 !important' : 'inherit'};
    }
`

export const Container = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 104px;
    justify-content: space-between;
    box-sizing: border-box;
`

export const InformationContainer = styled.div` 
    width: 65%; 
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
`

export const ImageContainer = styled.div`
    display: flex;
    width: 30%;
    height: 100%;,
    box-sizing: border-box;
`

export const Image = styled.div` 
    width: 100%;
    height: 100%;
    background-image: url(${props => props?.src});
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat; 
    border-radius: 8px;
`

export const Name = styled.h3`  
    font-size: ${props => props?.mode ? '14px' : '16px'}; 
    font-weight: 600;
    font-family: Visby;
    color: #222831;
    margin: 0;  
    overflow:hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`

export const Description = styled.h4`   
    font-size: ${props => props?.mode ? '12px' : '14px'}; 
    font-weight: 300;
    font-family: Visby;
    margin: 0; 
    overflow:hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
`

export const PriceContainer = styled.div`   
`

export const Price = styled.p`   
    margin: 0;
    font-size: ${props => props?.mode ? '14px' : '16px'};  
    font-weight: 600; 
    font-family: Visby;  
    height: 22px; 
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
`
