import React, { useEffect, useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

function CircularProgressWithLabel({ value, formatTime, timeLeft }) {
  return (
    <Box
      position='relative'
      display='inline-flex'
    >
      <CircularProgress
        sx={{
          color: '#1A6234'
        }}
        variant='determinate'
        value={value}
        size={140}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position='absolute'
        display='flex'
        alignItems='center'
        justifyContent='center'
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: '10px',
            alignItems: 'center' // Changed from 'center' to 'flex-start'
          }}
        >
          <Typography
            sx={{
              fontSize: '16px',
              fontFamily: 'Visby'
            }}
            variant='h5'
          >
            Válido por
          </Typography>
          <Typography
            variant='h5'
            style={{
              fontSize: '16px',
              fontWeight: 'bold'
            }}
          >
            {formatTime(timeLeft)}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

function CountdownTimer({ timestamp, setExpired, expired, couponCTA }) {
  const [timeLeft, setTimeLeft] = useState(0)
  const [progress, setProgress] = useState(100)

  useEffect(() => {
    const targetTime = new Date(timestamp).getTime()
    const initialTimeLeft = Math.max(targetTime - Date.now(), 0)
    setTimeLeft(initialTimeLeft)
    setProgress((initialTimeLeft / targetTime) * 100)

    const timer = setInterval(() => {
      const timeRemaining = Math.max(targetTime - Date.now(), 0)
      setTimeLeft(timeRemaining)
      setProgress((timeRemaining / initialTimeLeft) * 100)
      if (timeRemaining <= 0) {
        setExpired(true)
        clearInterval(timer)
      }
    }, 1000)

    return () => clearInterval(timer)
  }, [timestamp, setExpired])

  const formatTime = (milliseconds) => {
    if (!milliseconds) return '00:00'
    const totalSeconds = Math.floor(milliseconds / 1000)
    const days = Math.floor(totalSeconds / 86400)
    const hours = Math.floor((totalSeconds % 86400) / 3600)
    const minutes = Math.floor((totalSeconds % 3600) / 60)
    const seconds = totalSeconds % 60

    if (days > 0) {
      return `${days} ${days === 1 ? 'día' : 'días'}`
    } else if (hours > 0) {
      return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} horas`
    } else if (minutes > 0) {
      return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')} minutos`
    } else {
      return `${seconds} ${seconds === 1 ? 'segundo' : 'segundos'}`
    }
  }

  if (couponCTA === 'product_claim') {
    return null
  }

  if (expired) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          minHeight: '140px',
          boxSizing: 'border-box'
        }}
      >
        <Typography
          variant='h6' sx={{
            fontFamily: 'Visby',
            textAlign: 'center',
            color: '#000000',
            fontSize: '16px'
          }}
        >
          El boleto ha sido utilizado o ya no es válido
        </Typography>
      </div>
    )
  }

  return (
    <CircularProgressWithLabel value={progress} formatTime={formatTime} timeLeft={timeLeft} />
  )
}

export default CountdownTimer
